.container
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100vw;
    height: 60vh;
    padding: 2rem;
}

.desc
{
    width: 50%;
    text-align: left;
    line-height: 2rem;
}

.imgCat
{
    width: 50%;
    height: 75%;
}

@media only screen and (max-width: 973px) {
    .container
{
    flex-direction: column;
}

    .desc
{
    width: 95%;
    text-align: center;
    line-height: 1.2rem;
    font-size: 0.9rem;
}

.imgCat
{
    margin-top: 1rem;
    height: 30%;
    margin-bottom: 1.2rem;
}

}


@media only screen and (max-width: 606px) {
       

        .desc
    {
        
        font-size: 0.6rem;
    }


  }



