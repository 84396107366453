*
{
  padding:0;
  margin: 0;
  box-sizing: border-box;
}

body
{
  background: #4DA0B0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #D39D38, #4DA0B0);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #D39D38, #4DA0B0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: black;
}

section
{
  height: 100vh;
  width: 100vw;
}