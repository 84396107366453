main
{
    height: 85vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.photo
{
    height: 40%;
    margin-top: 2.5rem;
}

.photo img
{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.fact
{
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    text-align: center;
    width: 80%;
}

.btn
{
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn button
{
    background-color: #d39d38;
    width: 20%;
    height: 80%;
    
}

.btn:hover button
{
    background-color: #f5bf5a;
    cursor: pointer;
}


@media only screen and (max-width: 606px) {
       
    .fact
{
    font-size: 1rem;
}

}


