main
{

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 85vh;
    width: 100%;

    font-family: 'Montserrat', sans-serif;
}


label
{
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

select
{
    margin: 2rem;
    text-align: center;
    width: 40%;
    font-size: 1rem;
    padding: 0.2rem;
    border-radius: 25px;
    letter-spacing: 1px;
    background-color: #d39d38;
    border: 2px solid black;
}