@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;500;700&display=swap');




.container
{

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 85vh;
    width: 100%;
    gap: 3rem;
    font-family: 'Montserrat', sans-serif;
}



.catDesc
{
    font-size: 1.5rem;
    font-weight: 500;
    width: 40%;
    text-align: center;
    background-color: rgba(0,0,0,0.1);
    padding: 15px;
    border-radius: 25px;
}

.catImg
{
    width: 40%;
}

.catImg img
{
    width: 100%;
    height: 100%;
    border-radius: 25px;
    object-fit: contain;
}




@media only screen and (max-width: 1131px) {
    
        main
    {
        flex-direction: column;
    }



    .catDesc
    {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .catImg
    {
        width: 60%;
        height: 40%;
    }
        
    
    .catDesc
    {
      font-size: 1.1rem;
        
    }





  }


  @media only screen and (max-width: 658px) {
    .catDesc
    {
      font-size: 0.8rem;
        
    }

    .catImg
    {
        width: 80%;
        
    }

  }

  